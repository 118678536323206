import { IAuction, IChit, IEmi } from "interfaces/chit_interfaces";
import { ICustomer } from "interfaces/customer";
import {
  IGeneralFinance,
  IGeneralFinanceAPIResponse,
} from "interfaces/general_finance_interfaces";
import {
  FinanceStatus,
  IMicroFinance,
  IMicroFinanceAPIResponse,
} from "interfaces/micro_finance_interfaces";
import {
  IFinance,
  IPayment,
  IPaymentApiResponse,
} from "interfaces/payment_interfaces";
import {
  IBorrowing,
  ILendor,
  IPenalty,
  IPenaltyApiResponse,
  OtherStatus,
} from "interfaces/remaining_interfaces";
import axios from "react-axios";

export class ApiProvider {
  //FOR PROD
  static baseUrl: string = "https://vsm.onrender.com/";

  //FOR DEV
  // static baseUrl: string = "https://vsm-dev.onrender.com/";

  //FOR LOCALHOST
  // static baseUrl: string = "http://localhost:8080/";

  public static async getAllCustomers(endpoint : string): Promise<ICustomer[]> {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let customers: ICustomer[] = res.users.map((v, i) => {
                  let c: ICustomer = {
                    id: v._id,
                    customerId: v.customer_id,
                    firstName: v.first_name,
                    lastName: v.last_name,
                    phoneNumber: v.phone_number,
                    address: v.address,
                    photoId: v.photo_id,
                    idNumber: v.id_number
                  };
                  return c;
                });
                resolve(customers);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async addCustomers(data): Promise<boolean> {
    const url = this.baseUrl + "api/create-customer";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    // var raw = JSON.stringify({
    //   first_name: data.firstName,
    //   last_name: data.lastName,
    //   address: data.address,
    //   phone_number: data.phoneNumber,
    // });

    console.log(data);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async updateCustomers(
    data: ICustomer,
    id: string
  ): Promise<boolean> {
    const url = this.baseUrl + `api/update-customer/${id}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = JSON.stringify({
      first_name: data.firstName,
      last_name: data.lastName,
      address: data.address,
      phone_number: data.phoneNumber,
    });

    var requestOptions: RequestInit = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getAllChits(active : boolean): Promise<IChit[]> {
    const url = this.baseUrl + `api/fetch-all-chits?active=${active}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let chits: IChit[] = res.chits.map((v, i) => {
                  return {
                    id: v._id,
                    chit_id: v.chit_id,
                    chit_name: v.chit_name,
                    chit_amount: v.chit_amount,
                    start_date: v.start_date,
                    end_date: v.end_date,
                    number_of_months: v.number_of_months,
                    commission: v.commission,
                    customers: JSON.parse(v.customers),
                    auctions: JSON.parse(v.auctions),
                    emis: JSON.parse(v.emis),
                  };
                });
                resolve(chits);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async addChit(chit: IChit) {
    const url = this.baseUrl + "api/add-chit";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = JSON.stringify({
      chit_name: chit.chit_name,
      chit_amount: chit.chit_amount,
      start_date: chit.start_date,
      end_date: this.addMonthsToDate(
        chit.start_date,
        chit.number_of_months - 1
      ),
      number_of_months: chit.number_of_months,
      commission: chit.commission,
      customers: JSON.stringify(chit.customers),
      auctions: JSON.stringify(chit.auctions),
      emis: JSON.stringify(chit.emis),
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async updateChit(data: string, id: string): Promise<boolean> {
    const url = this.baseUrl + `api/update-chit/${id}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "PUT",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static addMonthsToDate(date: string, months: number): string {
    let timestamp = Date.parse(date);
    let dateObject = new Date(timestamp);
    let newDate = new Date(dateObject.setMonth(dateObject.getMonth() + months));
    return this.convertDate(newDate);
  }

  public static convertDate(date: Date) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(date.toString());
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
  }

  public static async addAuctionWinner(auctions: IAuction[], chitId: string) {
    const url = this.baseUrl + `api/update-chit/${chitId}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = JSON.stringify({
      auctions: JSON.stringify(auctions),
    });

    var requestOptions: RequestInit = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async addAuction(
    auctions: IAuction[],
    emis: IEmi[],
    chitId: string
  ) {
    const url = this.baseUrl + `api/update-chit/${chitId}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = JSON.stringify({
      auctions: JSON.stringify(auctions),
      emis: JSON.stringify(emis),
    });

    var requestOptions: RequestInit = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async addMicrofinance(raw) {
    const url = this.baseUrl + "api/add-microfinance";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getMicroFinances(
    endpoint: string
  ): Promise<IMicroFinanceAPIResponse> {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let microFinances: IMicroFinance[] = res.mf.map((v, i) => {
                  return {
                    id: v._id,
                    mf_id: v.mf_id,
                    customer: {
                      firstName: v.customer.first_name,
                      lastName: v.customer.last_name,
                      phoneNumber: v.customer.address,
                      address: v.customer.phone_number,
                    },
                    total_loan: v.total_loan,
                    issued_amount: v.issued_amount,
                    duration: v.duration,
                    duration_type: v.duration_type,
                    payments: JSON.parse(v.payments),
                    receiving_amounts: JSON.parse(v.receiving_amounts),
                    start_date: v.start_date,
                    due_date: v.due_date,
                    status: v.status,
                  };
                });

                let microFinanceApiReponse: IMicroFinanceAPIResponse = {
                  total: res.total,
                  message: res.message,
                  mf: microFinances,
                };
                resolve(microFinanceApiReponse);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async updateMicroFinance(
    data: string,
    id: string
  ): Promise<boolean> {
    const url = this.baseUrl + `api/update-microfinance/${id}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "PUT",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async addGeneralfinance(raw) {
    const url = this.baseUrl + "api/add-generalfinance";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getGeneralFinances(
    endpoint: string
  ): Promise<IGeneralFinanceAPIResponse> {
    const url = this.baseUrl + endpoint;
    
    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let microFinances: IGeneralFinance[] = res.gf.map((v, i) => {
                  return {
                    id: v._id,
                    gf_id: v.gf_id,
                    customer: {
                      firstName: v.customer.first_name,
                      lastName: v.customer.last_name,
                      phoneNumber: v.customer.address,
                      address: v.customer.phone_number,
                    },
                    total_loan: v.total_loan,
                    interest: v.interest,
                    duration: v.duration,
                    duration_type: v.duration_type,
                    payments: JSON.parse(v.payments),
                    receiving_amounts: JSON.parse(v.receiving_amounts),
                    start_date: v.start_date,
                    due_date: v.due_date,
                    status: v.status,
                  };
                });

                let microFinanceApiReponse: IGeneralFinanceAPIResponse = {
                  total: res.total,
                  message: res.message,
                  gf: microFinances,
                };
                resolve(microFinanceApiReponse);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async updateGeneralFinance(
    data: string,
    id: string
  ): Promise<boolean> {
    const url = this.baseUrl + `api/update-generalfinance/${id}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "PUT",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getMicroFinancesOfCustomer(
    id: string
  ): Promise<IMicroFinance[]> {
    const url = this.baseUrl + `api/get-microfinance-of-customer/${id}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let microFinances: IMicroFinance[] = res.mf.map((v, i) => {
                  return {
                    id: v._id,
                    mf_id: v.mf_id,
                    customer: {
                      firstName: v.customer.first_name,
                      lastName: v.customer.last_name,
                      phoneNumber: v.customer.address,
                      address: v.customer.phone_number,
                    },
                    total_loan: v.total_loan,
                    issued_amount: v.issued_amount,
                    duration: v.duration,
                    duration_type: v.duration_type,
                    payments: JSON.parse(v.payments),
                    receiving_amounts: JSON.parse(v.receiving_amounts),
                    start_date: v.start_date,
                    due_date: v.due_date,
                    status: v.status,
                  };
                });
                resolve(microFinances);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getGeneralFinancesOfCustomer(
    id: string
  ): Promise<IGeneralFinance[]> {
    const url = this.baseUrl + `api/get-generalfinance-of-customer/${id}`;
    
    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let microFinances: IGeneralFinance[] = res.gf.map((v, i) => {
                  return {
                    id: v._id,
                    gf_id: v.gf_id,
                    customer: {
                      firstName: v.customer.first_name,
                      lastName: v.customer.last_name,
                      phoneNumber: v.customer.address,
                      address: v.customer.phone_number,
                    },
                    total_loan: v.total_loan,
                    interest: v.interest,
                    duration: v.duration,
                    duration_type: v.duration_type,
                    payments: JSON.parse(v.payments),
                    receiving_amounts: JSON.parse(v.receiving_amounts),
                    start_date: v.start_date,
                    due_date: v.due_date,
                    status: v.status,
                  };
                });

                resolve(microFinances);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getChitsOfCustomer(id: string): Promise<IChit[]> {
    const url = this.baseUrl + "api/fetch-all-chits?active=true";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let chits: IChit[] = res.chits.map((v, i) => {
                  return {
                    id: v._id,
                    chit_id: v.chit_id,
                    chit_name: v.chit_name,
                    chit_amount: v.chit_amount,
                    start_date: v.start_date,
                    end_date: v.end_date,
                    number_of_months: v.number_of_months,
                    commission: v.commission,
                    customers: JSON.parse(v.customers),
                    auctions: JSON.parse(v.auctions),
                    emis: JSON.parse(v.emis),
                  };
                });
                let customerChits: IChit[] = chits.filter((c, i) => {
                  return c.customers.some((customerChit) => {
                    return customerChit.id === id;
                  });
                });
                resolve(customerChits);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async addPayment(raw) {
    const url = this.baseUrl + "api/add-payment";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (response.status == 401) {
                document.location.href = "/";
              }
              if (!response.ok) {
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getPayments(
    endpoint: string
  ): Promise<IPaymentApiResponse> {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                console.log(res);
                let payments: IPayment[] = res.payments.map((v, i) => {
                  let payment: IPayment = {
                    id: v._id,
                    paymentId: v.paymentId,
                    customer: {
                      id: v.customer._id,
                      customerId: v.customer.customer_id,
                      firstName: v.customer.first_name,
                      lastName: v.customer.last_name,
                      phoneNumber: v.customer.address,
                      address: v.customer.phone_number,
                    },
                    chitFinances: v.chitFinances.map((f, i) => {
                      return {
                        financeId: f.financeId,
                        customId: f.customId,
                        month: f.month,
                        amount: f.amount,
                        type: f.type,
                        financeStartDate : f.financeStartDate,
                        financeName : f.financeName
                      };
                    }),
                    microFinances: v.microFinances.map((f, i) => {
                      return {
                        financeId: f.financeId,
                        customId: f.customId,
                        month: f.month,
                        amount: f.amount,
                        type: f.type,
                        financeStartDate : f.financeStartDate,
                        financeName : f.financeName
                      };
                    }),
                    generalFinances: v.generalFinances.map((f, i) => {
                      return {
                        financeId: f.financeId,
                        customId: f.customId,
                        month: f.month,
                        amount: f.amount,
                        type: f.type,
                        financeStartDate : f.financeStartDate,
                        financeName : f.financeName
                      };
                    }),
                    totalAmount: v.totalAmount,
                    remarks: v.remarks,
                    createdAt: new Date(v.createdAt),
                  };
                  return payment;
                });

                let paymentApiReponse: IPaymentApiResponse = {
                  total: res.total,
                  message: res.message,
                  payments: payments,
                };
                resolve(paymentApiReponse);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async post(raw, endpoint) {
    const url = this.baseUrl + endpoint;
    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async get(endpoint) {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(res);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async put(endpoint, raw, headers?) {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "PUT",
      body: raw,
      headers: headers ?? myHeaders,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async delete(endpoint) {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject(false);
              } else {
                resolve(true);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getPenalties(
    endpoint: string
  ): Promise<IPenaltyApiResponse> {
    const url = this.baseUrl + endpoint;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let penalties: IPenalty[] = res.penalties.map((v, i) => {
                  return {
                    id: v._id,
                    penalty_id: v.penalty_id,
                    customer: {
                      customerId: v.customer.customer_id,
                      firstName: v.customer.first_name,
                      lastName: v.customer.last_name,
                      phoneNumber: v.customer.address,
                      address: v.customer.phone_number,
                    },
                    status: v.status,
                    finance: v.finance,
                    type: v.type,
                    amount: v.amount,
                    remarks: v.remarks,
                    created: new Date(v.createdAt),
                  };
                });

                let penaltyApiReponse: IPenaltyApiResponse = {
                  total: res.total,
                  penalties: penalties,
                };
                resolve(penaltyApiReponse);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getAllLendors(): Promise<ILendor[]> {
    const url = this.baseUrl + "api/get-all-lendors";

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let customers: ILendor[] = res.data.map((v, i) => {
                  let c: ILendor = {
                    id: v._id,
                    lendorId: v.lendor_id,
                    firstName: v.first_name,
                    lastName: v.last_name,
                    phoneNumber: v.phone_number,
                    address: v.address,
                  };
                  return c;
                });
                resolve(customers);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  public static async getAllBorrowings(status : OtherStatus): Promise<IBorrowing[]> {
    const url = this.baseUrl + `api/get-all-borrowing?status=${status}`;

    const token = localStorage.getItem("vsm");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return new Promise<any>(
      (resolve: (data: any) => void, reject: (error: any) => void): void => {
        fetch(url, requestOptions)
          .then((response) => {
            return response.json().then((res) => {
              if (!response.ok) {
                if (response.status == 401) {
                  document.location.href = "/";
                }
                reject({
                  status: response.status ? response.status.toString() : "",
                  message:
                    (res.error.message && res.error.message.value) ||
                    "Request Failed",
                });
              } else {
                let customers: IBorrowing[] = res.data.map((v, i) => {
                  let c: IBorrowing = {
                    id: v._id,
                    borrowing_id: v.borrowing_id,
                    lendor: {
                      firstName: v.lendor.first_name,
                      lastName: v.lendor.last_name,
                      phoneNumber: v.lendor.address,
                      address: v.lendor.phone_number,
                    },
                    interest: v.interest,
                    type: v.type,
                    loanAmount: v.loan_amount,
                    receivedAmount: v.received_amount,
                    receivedDate: v.received_date,
                    paidAmount: v.paid_amount,
                    status: v.status
                  };
                  return c;
                });
                resolve(customers);
              }
            });
          })
          .catch((error) => console.log("error", error));
      }
    );
  }
}
